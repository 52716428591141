
  
  .file-picker + [type='file']{
    display:none;
  }

/** crea a class for dropzone file*/
.file-picker{
  border-radius: 5px;
  background-color: #f0f0f0;
  min-height: 200px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  width: 200px;
}