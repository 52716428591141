.root{
    margin-top: 2%;
    text-align: center;
}

.imgBox{
    margin: 2%
}

.btnPhoto{
    margin: 60% 5% 5%;
}

.img{
    height: inherit;
    max-width: inherit;
}

.input{
    display:none;
}

.divContainer {
    background: #FFFFFF !important;
}
.divImage {
    text-align: center;
    margin-top: 5%;
    height: 100px;
}
