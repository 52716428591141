
.photo-button {
    width: 70px;
    height: 70px;
    top: calc(100% - 60px);
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    position: absolute;
  }
  .circle {
    position: absolute;
    top: 12%;
    left: 12%;
    bottom: 12%;
    right: 12%;
    border-radius: 100%;
    background-color: #ffffff;
    opacity: 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}
  .ring {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 100%;
    border: 0.3em solid #ffffff;
    opacity: 0.8;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}
  .photo-button .circle, .photo-button .ring {
    transition: all 0.25s;
  }
  .photo-button:hover .circle {
    opacity: 1;
  }
  .photo-button:active .ring {
    opacity: 1;
  }
  .photo-button:active .circle {
    opacity: 0.5;
  }