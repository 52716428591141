.msts_theme_example {
    border: 1px solid silver;
    display: row;

    .msts__heading {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;

        .msts__side {
            padding: 5px;
            text-align: center;
            color: #fff;
            font-weight: bold;
        }

        .msts__side_available {
            background-color: #00a65a;
            display: column;
            flex: 1;
        }

        .msts__side_selected {
            background-color: #2ecc71;
            display: column;
            flex: 1;
        }
    }

    .msts__subheading {
        display: flex;

        .msts__side_filter {
            padding: 5px;
            display: column;
            justify-content: center;
            flex: 1;
        }
    }


    .msts__body {
        display: flex;

        .msts__side_available {
            flex: 1;

        }

        .msts__side_selected {
            flex: 1;
        }
    }

    .msts__footer {
        .msts__side {
            display: none;
        }
    }

    .msts__list {
        height: 140px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .msts__list-item {
        padding: 5px 10px;
        transition: background-color ease-in 0.1s, color ease-in 0.1s;
        list-style: none;

        &:hover {
            background-color: #5A8DEE;
            color: #fff;
        }

        &_disabled {
            background-color: #ecf0f1;
            color: #7f8c8d;

            &:hover {
                background-color: #ecf0f1;
                color: #7f8c8d;
            }
        }

        &_highlighted {
            background-color: rgba(41, 128, 185, 0.25);
        }
    }

    .msts__control {
        border: none;
        background: none;
        cursor: pointer;
        padding: 10px 3px;
        color: #bdc3c7;
        transition: color ease 0.15s;
        display: flex;

        &:hover {
            color: #95a5a6;
        }

        &[disabled] {
            color: #ecf0f1;
        }
    }

    .msts__control_select-all {

        direction: ltr;
        word-wrap: break-word;
        box-sizing: border-box;
        font-family: inherit;
        overflow: visible;
        text-transform: none;
        -webkit-appearance: button;
        display: column;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 0 solid transparent;
        padding: 0.467rem 1.5rem;
        font-size: 1rem;
        line-height: 1.6rem;
        border-radius: 0.267rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin: 1rem !important;
        border-color: #23bd70 !important;
        background-color: #39DA8A !important;
        color: #fff;
        cursor: pointer;

        &:after {
            content: "❯";
        }

        &:hover {
            color: #fff;
        }
    }

    .msts__control_deselect-all {
        direction: ltr;
        word-wrap: break-word;
        box-sizing: border-box;
        font-family: inherit;
        overflow: visible;
        text-transform: none;
        -webkit-appearance: button;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 0 solid transparent;
        padding: 0.467rem 1.5rem;
        font-size: 1rem;
        line-height: 1.6rem;
        border-radius: 0.267rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin: 1rem !important;
        border-color: #23bd70 !important;
        background-color: #39DA8A !important;
        color: #fff;
        cursor: pointer;

        &:after {
            content: "❮";
        }
    }

    .msts__filter {
        position: relative;
    }

    .msts__filter-input {
        width: 100%;
        box-sizing: border-box;
        padding: 5px;
        border: 1px solid silver;
    }

    .msts__filter-clear {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0 10px;
        font-size: 1.25em;
        color: silver;
        transition: color ease 0.15s;

        &:after {
            content: "×";
            vertical-align: middle;
        }

        &:hover {
            color: #c0392b;
        }
    }
}

.msts_theme_example.msts_disabled {
    background-color: #ecf0f1;
}

//estilo para dispositivos móviles
@media (max-width: 768px) {
    .msts_theme_example {
       .msts__heading{
              flex-direction: column;
       }
       .msts__subheading{
              flex-direction: column;
       }
       .msts__body {
        display: column;
        flex-direction: column;

        .msts__side_available {
            flex: 1;
            display: row;

        }

        .msts__side_selected {
            flex: 1;
            display: row;
        }
        .msts__control{
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: center;
        }
    }
    }
}