
.app__canvas {
   position: absolute;
   top:0px;
   left:0px;
}
 .foto-zone {
    display: flex;
    width: 100vw; 
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
 }
