.modal-dialog-d{
width: 400px !important;
position: relative;
left: 40%;
}
.button-d{
    color: white;
    background-color: #39DA8A;
    border-radius: 5px;
    border: 0px;
    width: 100%;
    padding: 10px;
}
.displayNone{
    display:none
}